<template>
	<div id="menus">
		<div class="tabela-menus-telefone">
			<div class="col-12 novo-menu">
				<v-btn class="primary-button" raised @click="dialog = true">
					Cadastrar Menu do Telefone
				</v-btn>
			</div>
			<CustomTable 
				v-if="headers != ''"
				:action="'getMenusTelefone'"
				:getter="$store.getters.menus_telefone"
				:headers="headers"
				:search="true"
				:title="name"
				:icone="'fa-bars'"
				:pagination="true"
				:filters="filtros"
				ref="tabela"
			>
				<template v-slot:acoes="{ item }">
					<v-btn class="primary-button" raised small @click="editar(item._id)">
						<i class="fas fa-cog"></i>
						Editar
					</v-btn>
				</template>
			</CustomTable>
			<v-dialog v-model="dialog" persistent max-width="1000px">
				<v-card>
					<v-card-title>
						<span v-if="dados._id" class="headline">Editar Menu Telefone</span>
						<span v-else class="headline">Novo Menu</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_menu">
								<v-tabs
										v-model="tab"
										centered
										dark
										icons-and-text
										background-color="#11263C"
									>
									<v-tabs-slider></v-tabs-slider>

									<v-tab href="#cadastro_geral">
										Cadastro Geral
									</v-tab>

									<v-tab href="#configuracoes_finalizacao">
										Configuração de Finalização
									</v-tab>

									<v-tab href="#opcao_menu">
										Opções do Menu
									</v-tab>

								</v-tabs>

								<v-tabs-items v-model="tab">
									<v-tab-item
										value="cadastro_geral"
									>
										<div class="row">
											<div class="col-3">
												<v-text-field 
													:rules="[v => !!v || 'Campo Texto Menu obrigatório']" 
													v-model="dados.texto_menu" 
													label="Texto Menu" 
													placeholder="Texto Menu" 
													background-color="white"
													hide-details
													outlined
												/>
											</div>
											<div class="col-3">
												<v-autocomplete
													:rules="[v => (v.length > 1) || 'Campo Telefone obrigatório']" 
													:items="telefones"
													item-text="apelido" 
													item-value="_id"
													v-model="dados.telefone" 
													label="Telefone"
													placeholder="Telefone" 
													background-color="white"
													hide-details
													outlined
												/>
											</div>
											<div class="col-3">
												<v-autocomplete
													:rules="[v => (v.length > 1) || 'Campo Empresa obrigatório']" 
													:items="empresas"
													item-text="nome" 
													item-value="_id"
													v-model="dados.empresa" 
													label="Empresa"
													placeholder="Empresa" 
													background-color="white"
													hide-details
													outlined
												/>
											</div>
											<div class="col-3">
												<v-select 
													:items="[{_id:true,nome:'Sim'}, {_id:false,nome:'Não'}]" 
													item-text="nome" 
													item-value="_id"
													v-model="dados.status" 
													label="Status" 
													placeholder="Status" 
													background-color="white"
													hide-details
													outlined
												/>
											</div>
											<div class="col-12">
												<v-tabs
													v-model="dados.tipo"
													centered
													dark
													icons-and-text
													background-color="#11263C"
												>
													<v-tabs-slider></v-tabs-slider>

													<v-tab href="#chat">
														Chat
													</v-tab>

													<v-tab href="#agendamento">
														Agendamento
													</v-tab>

													<v-tab href="#mensagens_agendamento">
														Respostas de Agendamento ao Usuário
													</v-tab>

													<v-tab href="#mensagens_agendamento_invalida">
														Respostas de Agendamento ao Usuario Opção Inválida
													</v-tab>


												</v-tabs>

												<v-tabs-items v-model="dados.tipo">
													<v-tab-item
														value="chat"
													>
														<div class="row">
															<!-- <div class="col-3">
																<v-select 
																	:rules="[v => !!v || 'Campo Tipo Menu obrigatório']" 
																	:items="[{_id:'chat',nome:'Chat'}, {_id:'agendamento',nome:'Agendamento'}]" 
																	item-text="nome" 
																	item-value="_id"
																	v-model="dados.tipo" 
																	label="Tipo Menu" 
																	placeholder="Tipo Menu" 
																	background-color="white"
																	hide-details
																	outlined
																/>
															</div> -->
															<div class="col-3">
																<v-autocomplete
																	:rules="[v => !!v && dados.tipo =='chat' || 'Campo Setor obrigatório']" 
																	:items="setores"
																	item-text="nome" 
																	item-value="_id"
																	v-model="dados.setor" 
																	label="Setor"
																	placeholder="Setor" 
																	background-color="white"
																	hide-details
																	outlined
																/>
															</div>
														</div>
													</v-tab-item>

													<v-tab-item
														value="agendamento"
													>

														<div class="row">
															<div v-if="dados.tipo =='agendamento'" class="col-4">
																<v-text-field 
																	:rules="[v => !!v || 'Campo Máximo de dias para buscar obrigatório']" 
																	v-model="dados.maximo_dias_agendamento_busca" 
																	label="Máximo de dias para buscar" 
																	placeholder="Máximo de dias para buscar, Horario disponivel" 
																	type="number"
																	background-color="white"
																	hide-details
																	outlined
																/>
															</div>

															<div v-if="dados.tipo =='agendamento'" class="col-4">
																<v-text-field 
																	:rules="[v => !!v || 'Campo Máximo de dias para mostrar no menu obrigatório']" 
																	v-model="dados.quantidade_dias_agendamento_mostrar" 
																	label="Máximo de dias para mostrar no menu" 
																	placeholder="Máximo de dias para mostrar no menu" 
																	type="number"
																	background-color="white"
																	hide-details
																	outlined
																/>
															</div>

															<div v-if="dados.tipo =='agendamento'" class="col-4">
																<v-text-field 
																	:rules="[v => !!v || 'Campo Máximo de horarios para mostrar no menu obrigatório']" 
																	v-model="dados.quantidade_horarios_agendamento_mostrar" 
																	label="Máximo de horarios para mostrar no menu" 
																	placeholder="Máximo de horarios para mostrar no menu" 
																	type="number"
																	background-color="white"
																	hide-details
																	outlined
																/>
															</div>

															<div class="col-12">

																<v-tabs
																	v-model="dados.tipo_agendamento"
																	centered
																	dark
																	icons-and-text
																	background-color="#11263C"
																>
																	<v-tabs-slider></v-tabs-slider>

																	<v-tab href="#calendar">
																		Calendar
																	</v-tab>

																	<v-tab href="#api">
																		Api
																	</v-tab>

																</v-tabs>

																<v-tabs-items v-model="dados.tipo_agendamento">
																	<v-tab-item
																		value="calendar"
																	>
																		<div class="row">

																			<div v-if="dados.tipo_agendamento =='calendar' && dados.tipo =='agendamento'" class="col-6">
																				<v-btn-toggle style="width: 100%;">
																					<v-btn style="    min-height: 56px !important;" @click="dialog_adicionar_calendario =true">
																						<v-icon>fas fa-calendar-plus</v-icon>
																					</v-btn>
																					<v-select 
																						:items="calendar_dados" 
																						item-text="apelido" 
																						item-value="_id"
																						v-model="dados.calendar" 
																						@change="getCalendarId"
																						label="Google Calendar" 
																						placeholder="Google Calendar" 
																						background-color="white"
																						hide-details
																						outlined
																					/>
																				</v-btn-toggle>
																			</div>

																			<div v-if="dados.calendar != '' && dados.tipo_agendamento =='calendar' && dados.tipo =='agendamento'" class="col-6">
																				<v-select 
																					:items="calendar_id_dados" 
																					item-text="nome" 
																					item-value="id"
																					v-model="dados.calendar_id" 
																					label="Google Calendar Id" 
																					placeholder="Google Calendar Id" 
																					background-color="white"
																					hide-details
																					outlined
																				/>
																			</div>

																			<div v-if="dados.tipo =='agendamento' && dados.tipo_agendamento =='calendar'" class="col-12">
																				<v-text-field 
																					:rules="[v => !!v || 'Campo Duração Atendimento em minutos obrigatório']" 
																					v-model="dados.duracao_atendimento" 
																					label="Duração Atendimento em minutos" 
																					placeholder="Duração Atendimento em minutos" 
																					type="number"
																					background-color="white"
																					hide-details
																					outlined
																				/>
																			</div>
																		</div>
																	</v-tab-item>

																	<v-tab-item
																		value="api"
																	>
																		<div class="row">

																			<div v-if="dados.tipo_agendamento =='api' && dados.tipo =='agendamento'" class="col-6">
																				<v-text-field 
																					:rules="[v => !!v || 'Campo Api token get horários disponíveis obrigatório']" 
																					v-model="dados.token_api_horarios_disponiveis" 
																					label="Api token get horários disponíveis" 
																					placeholder="Api token get horários disponíveis" 
																					background-color="white"
																					hide-details
																					outlined
																				/>
																			</div>

																			<div v-if="dados.tipo_agendamento =='api' && dados.tipo =='agendamento'" class="col-6">
																				<v-text-field 
																					:rules="[v => !!v || 'Campo Api get horários disponíveis obrigatório']" 
																					v-model="dados.url_api_horarios_disponiveis" 
																					label="Api get horários disponíveis" 
																					placeholder="Api get horários disponíveis" 
																					background-color="white"
																					hide-details
																					outlined
																				/>
																			</div>

																			<div v-if="dados.tipo_agendamento =='api' && dados.tipo =='agendamento'" class="col-6">
																				<v-text-field 
																					:rules="[v => !!v || 'Campo Api post horário obrigatório']" 
																					v-model="dados.url_api_post_horario" 
																					label="Api post horário" 
																					placeholder="Api post horário" 
																					background-color="white"
																					hide-details
																					outlined
																				/>
																			</div>
																		</div>
																	</v-tab-item>
																</v-tabs-items>
																
															</div>
														</div>
													</v-tab-item>

													<v-tab-item
														value="mensagens_agendamento"
													>
														<div class="row">
															<div class="col-6">
																<v-textarea
																	clearable
																	clear-icon="mdi-close-circle"
																	v-model="dados.mensagem_pergunta_dias_agendamento_superior" 
																	label="Texto para pergunta de dias de agendamento (parte superior)" 
																	placeholder="Texto para pergunta de dias de agendamento (parte superior)" 
																	background-color="white"
																	hide-details
																	outlined
																></v-textarea>
															</div>

															<div class="col-6">
																<v-textarea
																	clearable
																	clear-icon="mdi-close-circle"
																	v-model="dados.mensagem_pergunta_dias_agendamento_inferior" 
																	label="Texto para pergunta de dias de agendamento (parte inferior)" 
																	placeholder="Texto para pergunta de dias de agendamento (parte inferior)" 
																	background-color="white"
																	hide-details
																	outlined
																></v-textarea>
															</div>
														</div>
													</v-tab-item>

													<v-tab-item
														value="mensagens_agendamento_invalida"
													>
														<div class="row">
															<div class="col-6">
																<v-textarea
																	clearable
																	clear-icon="mdi-close-circle"
																	v-model="dados.mensagem_pergunta_dias_agendamento_opcao_incorreta_superior" 
																	label="Texto para pergunta de dias de agendamento (parte superior)" 
																	placeholder="Texto para pergunta de dias de agendamento (parte superior)" 
																	background-color="white"
																	hide-details
																	outlined
																></v-textarea>
															</div>

															<div class="col-6">
																<v-textarea
																	clearable
																	clear-icon="mdi-close-circle"
																	v-model="dados.mensagem_pergunta_dias_agendamento_opcao_incorreta_inferior" 
																	label="Texto para pergunta de dias de agendamento (parte inferior)" 
																	placeholder="Texto para pergunta de dias de agendamento (parte inferior)" 
																	background-color="white"
																	hide-details
																	outlined
																></v-textarea>
															</div>
														</div>
													</v-tab-item>
												</v-tabs-items>
											</div>
										</div>
									</v-tab-item>

									<v-tab-item
										value="configuracoes_finalizacao"
									>
										<div class="row">
											<div class="col-12">
												<v-textarea
													clearable
													clear-icon="mdi-close-circle"
													v-model="dados.texto_finalizacao" 
													label="Texto para finalização do atendimento" 
													placeholder="Texto para finalização do atendimento" 
													background-color="white"
													hide-details
													outlined
												></v-textarea>
											</div>
										</div>
									</v-tab-item>

									<v-tab-item
										value="opcao_menu"
									>
										<div class="row">
											<div class="col-12">
												<h3>Opção do Menu</h3>
												<div class="horario" v-for="(codigo_menu, i) in dados.codigo_menu" :key="i">
													<div class="inputs">
														<div class="row">
															<div class="col-md-12">
																<div class="row">
																	<div class="col-md-6">
																		<v-text-field 
																			:rules="[v => !!v || 'Campo Opção do Menu obrigatório']" 
																			v-model="dados.codigo_menu[i]" 
																			label="Opção do Menu" 
																			placeholder="Opção do Menu" 
																			background-color="white"
																			hide-details
																			outlined
																		/>
																	</div>
																	<div class="col-md-6">
																		<v-btn v-if="dados.codigo_menu.length > 1" type="button" @click="removeCodigoMenu(i)" color="primary" raised class="remover-horario">
																			<i class="fas fa-minus"></i>
																		</v-btn>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<v-btn class="primary-button" raised @click="addCodigoMenu">
													<i class="fas fa-plus" style="margin-right: 10px;"></i> Adicionar Opção Menu
												</v-btn>
											</div>
										</div>
									</v-tab-item>
								</v-tabs-items>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="close">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" small @click="enviar">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<DialogMensagem :visible="dialog_resposta" :mensagem="resposta" @close="dialog_resposta=false"/>
			<Loader v-if="loading"/>

			<v-dialog v-model="dialog_adicionar_calendario" persistent max-width="600px">
				<v-card>
					<v-card-title>
						<span class="headline">Adicionar Calendário</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-form ref="form_empresa">
								<div class="row">
									<div class="col-6">
										<v-text-field 
											:rules="[v => !!v || 'Campo Apelido Calendar obrigatório']" 
											v-model="calendar.apelido" 
											label="Apelido Calendar" 
											placeholder="Apelido Calendar" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
									<div class="col-6">
										<v-autocomplete
											:rules="[v => (v.length > 1) || 'Campo Empresa obrigatório']" 
											:items="empresas"
											item-text="nome" 
											item-value="_id"
											v-model="calendar.empresa" 
											label="Empresa"
											placeholder="Empresa" 
											background-color="white"
											hide-details
											outlined
										/>
									</div>
								</div>
							</v-form>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="darken-1" text style="text-transform: capitalize; font-size: 16px;" @click="closedialogcalendario">
							Cancelar
						</v-btn>
						<v-btn class="primary-button" small @click="enviarcalendario">
							Salvar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
	// importa o store
	import store from '@/store'
	// importa o componente de DialogMensagem 
	import DialogMensagem from '@/components/DialogMensagem.vue'
	// importa o componente de Loader 
	import Loader from '@/components/Loader.vue'
	// importa o componente de CustomTable 
	import CustomTable from '@/components/CustomTable.vue'
	import io from 'socket.io-client';

	// exporta o componente
	export default {
		// nome do componente
		// componentes filhos
		components: {
			// componente de DialogMensagem
			DialogMensagem,
			// componente de CustomTable
			CustomTable,
			// componente de Loader
			Loader,
		},
		// dados do componente
		data: () => ({
			// nome do componente
			name: 'Menus do Telefone',
			name_adicionar: 'Menu do Telefone',
			empresas: [],
			setores: [],
			telefones: [],
			calendar_dados:[],
			calendar_id_dados:[],
			tab:'',
			socket:io(process.env.VUE_APP_SOCKET_URL),
			// variável para mostrar a modal para editar/criar um menu
			dialog: false,
			// variável para mostrar a modal de informação
			dialog_resposta: false,
			dialog_adicionar_calendario:false,
			tab_agendamento: '',
			// variável para a mensagem de resposta
			resposta: {},
			// variável para o loading
			loading: false,
			// variável para criar/editar menu
			dados: {
				_id: '',
				texto_menu: '',
				setor: '',
				empresa: '',
                telefone:'',
                tipo:'',
                status:true,
                tipo_agendamento: '',
                codigo_menu:[],
                texto_finalizacao:'',
				calendar:'',
				calendar_id:'',
				maximo_dias_agendamento_busca: '',
				quantidade_dias_agendamento_mostrar: ''	,
				quantidade_horarios_agendamento_mostrar: '',
				url_api_horarios_disponiveis: '',
				url_api_post_horario:'',
				token_api_horarios_disponiveis: '',
				mensagem_pergunta_dias_agendamento_superior:'',
				mensagem_pergunta_dias_agendamento_inferior:'',
				mensagem_pergunta_dias_agendamento_opcao_incorreta_superior:'',
				mensagem_pergunta_dias_agendamento_opcao_incorreta_inferior:'',

			},
			calendar:{
				empresa:'',
				apelido:''
			},
			calendar_retorno_id:'',
			// variável para o cabeçalho da tabela
			headers: [
				{
					value: 'texto_menu',
					text: 'Texto do Menu',
					sortable: true,
				},
				{
					value: 'tipo',
					text: 'Tipo do Menu',
					sortable: true,
				},
				{
					value: 'status',
					text: 'Status',
					sortable: true,
				},
				{
					value: 'empresa[0].nome',
					text: 'Empresa',
					sortable: false,
				},
				{
					value: 'setor[0].nome',
					text: 'Setor',
					sortable: false,
				},
				{
					value: 'calendar[0].apelido',
					text: 'Calendar',
					sortable: false,
				},
				{
					value: 'acoes',
					sortable: false,
					text: 'Ações',
				},
			],
			// variável para os filtros da tabela
			filtros: {
				perPage: 20,
			},
		}),
		// funções deste componente
		methods: {
			addCodigoMenu(){
				this.dados.codigo_menu.push('')
			},
			removeCodigoMenu(index){
				if (index > -1) {
					this.dados.codigo_menu.splice(index, 1);
				}
			},
			// função para enviar um empresa
			async enviarcalendario(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_empresa.validate()){
					// coloca o componente como loading
					this.loading = await true
					let dados = this.calendar

					// // faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('postCalendar', dados)
					// // caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200 || !resp.data.sucesso){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.error.join('\n')
						// mostra a mensagem
						this.dialog_resposta = true
						// retira o loading do componente
						this.loading = false
					// caso tenha dado algum erro
					}else{
						this.calendar_retorno_id = resp.data.dados.retorno._id;

						window.open(resp.data.link_calendar, "_blank", "toolbar=false,scrollbars=false,resizable=false,top=500,left=500,width=800,height=800");
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Calendario '  +  (this.calendar._id ? 'editada!' : 'cadastrada!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await 'Calendario ' + (this.calendar._id ? 'editada' : 'cadastrada') + ' com sucesso!'
						// mostra a mensagem
						this.closedialogcalendario()
					}
				}
			},
			// função que roda quando é fechada a modal de create/edit empresa
			closedialogcalendario(){
				// fecha a modal
				this.dialog_adicionar_calendario = false
				// limpa os dados locais do empresa
				this.config_nome = {
					empresa:''
				}
			},
			// função para enviar um menu
			async enviar(){
				// caso os campos do formulário estejam válidos
				if (this.$refs.form_menu.validate()){
					// coloca o componente como loading
					this.loading = await true
					// coloeta os dados do menu
					let dados = await {
						// coleta o nome do menu
						nome: await this.dados.nome,
						texto_menu: await this.dados.texto_menu,
						empresa: await this.dados.empresa,
						telefone: await this.dados.telefone,
						setor: await this.dados.setor,
						tipo: await this.dados.tipo,
						status: await this.dados.status,
						tipo_agendamento: await this.dados.tipo_agendamento,
						codigo_menu: await this.dados.codigo_menu,
						texto_finalizacao: await this.dados.texto_finalizacao,
						calendar: await this.dados.calendar,
						calendar_id: await this.dados.calendar_id,
						maximo_dias_agendamento_busca: await this.dados.maximo_dias_agendamento_busca,
						quantidade_dias_agendamento_mostrar: await this.dados.quantidade_dias_agendamento_mostrar,
						quantidade_horarios_agendamento_mostrar: await this.dados.quantidade_horarios_agendamento_mostrar,
						duracao_atendimento: await this.dados.duracao_atendimento,
						url_api_horarios_disponiveis: await this.dados.url_api_horarios_disponiveis,
						url_api_post_horario: await this.dados.url_api_post_horario,
						token_api_horarios_disponiveis: await this.dados.token_api_horarios_disponiveis,
					}
					// caso exista um _id 
					if(this.dados._id){
						// coleta o id do menu
						dados.codigo = await this.dados._id
					}
					// faz a requisição para o back para coletar os menus
					var resp = await store.dispatch('postMenuTelefone', dados)
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status != 200 || !resp.data.sucesso){
						// atribui o título da mensagem 
						this.resposta.titulo = await 'Algo deu errado!'
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await  resp.data.message || resp.data.erro
						// mostra a mensagem
						this.dialog_resposta = true
					// caso tenha dado algum erro
					}else{
						// atribui o título da mensagem 
						this.resposta.titulo = await this.name_adicionar + ' '  +  (this.dados._id ? 'editado!' : 'cadastrado!')
						// atribui o corpo da mensagem 
						this.resposta.mensagem = await this.name_adicionar + ' ' + (this.dados._id ? 'editado' : 'cadastrado') + ' com sucesso!'
						// mostra a mensagem
						this.close()
						// fecha a modal de create/edit menu
						this.dialog_resposta = true
					}
					// retira o loading do componente
					this.loading = false
					// atualiza a tabela
					this.$refs.tabela.init()
				}
			},
			// função para coletar um setor para editar
			async editar(_id){
				// coloca o componente como loading
				this.loading = true
				// faz um dispatch para uma action no vuex store para pegar um setor passando o id 
				var resp = await store.dispatch('getMenuTelefone', _id)
				// caso o status da resposta seja 200 (deu certo) 
				if(resp.status == 200 || resp.data.sucesso){
					// atribui os dados vindos do back à váriável local
					this.dados._id = await resp.data._id || ''
                    this.dados.texto_menu =  await resp.data.texto_menu || ''
                    this.dados.setor =  await resp.data.setor || null
                    this.dados.empresa =  await resp.data.empresa || ''
                    this.dados.telefone = await resp.data.telefone || ''
                    this.dados.tipo = await resp.data.tipo || ''
                    this.dados.status = await resp.data.status || true
                    this.dados.tipo_agendamento = await resp.data.tipo_agendamento || ''
                    this.dados.codigo_menu = await resp.data.codigo_menu || ''
                    this.dados.texto_finalizacao = await resp.data.texto_finalizacao || ''
                    this.dados.calendar = await resp.data.calendar || ''
                    this.dados.calendar_id = await resp.data.calendar_id || ''
					this.dados.quantidade_dias_agendamento_mostrar = await resp.data.quantidade_dias_agendamento_mostrar || ''
					this.dados.maximo_dias_agendamento_busca = await resp.data.maximo_dias_agendamento_busca || ''
					this.dados.quantidade_horarios_agendamento_mostrar = await resp.data.quantidade_horarios_agendamento_mostrar || ''
					this.dados.duracao_atendimento = await resp.data.duracao_atendimento || ''
					this.dados.url_api_horarios_disponiveis = await resp.data.url_api_horarios_disponiveis || ''
					this.dados.token_api_horarios_disponiveis = await resp.data.token_api_horarios_disponiveis || ''
					this.dados.url_api_post_horario = await resp.data.url_api_post_horario || ''
						
					await this.getCalendarId();
					// mostra a modal de creat/edit dados
					this.dialog = true
				}else{
					// atribui o título da mensagem 
					this.resposta.titulo = await 'Algo deu errado!'
					// atribui o corpo da mensagem 
					this.resposta.mensagem = await  resp.data.message || resp.data.error
					// mostra a mensagem
					this.dialog_resposta = true
				}
				// retira o loading do componente
				this.loading = false
				// atualiza a tabela
				this.$refs.tabela.init()
			},
			// funções de incio do componente
			async init(){
				await this.setInciaisUsuer()
				// função para pegar as empresas
				this.getEmpresas()
				// função para pegar as telefones
				this.getTelefones()
				// função para pegar as setores
				this.getSetores()

				this.getCalendars()
			},
			// função para pegar as empresas
			async getEmpresas(){
				// faz a requisição para o back para coletar as empresas
				var resp = await store.dispatch('getEmpresaSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel empresas
					this.empresas = resp.data
				}
			},
			// função para pegar as empresas
			async getCalendars(){
				// faz a requisição para o back para coletar as empresas
				var resp = await store.dispatch('getCalendarSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel empresas
					this.calendar_dados = resp.data
				}
			},
			// função para pegar as empresas
			async getCalendarId(){
				if(this.dados.calendar != ''){
					// faz a requisição para o back para coletar as empresas
					var resp = await store.dispatch('getCalendarIDSelect',{codigo_calendar:this.dados.calendar})
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status == 200){
						// atribui a resposta na variavel empresas
						this.calendar_id_dados = resp.data.dados
					}
				}
			},
			// função para pegar as empresas
			async getTelefones(){
				// faz a requisição para o back para coletar as telefones
				var resp = await store.dispatch('getTelefoneSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel telefones
					this.telefones = resp.data
				}
			},
			// função para pegar as empresas
			async getSetores(){
				// faz a requisição para o back para coletar as telefones
				var resp = await store.dispatch('getSetoresSelect')
				// caso o status da resposta seja 200 (deu certo)
				if(resp.status == 200){
					// atribui a resposta na variavel telefones
					this.setores = resp.data
				}
			},
			// função que roda quando é fechada a modal de create/edit menu
			close(){
				// fecha a modal
				this.dialog = false
				// limpa os dados locais do menu
				this.dados =  {
				_id: '',
				texto_menu: '',
				setor: '',
				empresa: '',
                telefone:'',
                tipo:'',
                status:true,
                tipo_agendamento: '',
                codigo_menu:[],
                texto_finalizacao:'',
				calendar:'',
				calendar_id:'',
				maximo_dias_agendamento_busca: '',
				quantidade_dias_agendamento_mostrar: ''	,
				quantidade_horarios_agendamento_mostrar: '',
				url_api_horarios_disponiveis: '',
				url_api_post_horario:'',
				token_api_horarios_disponiveis: '',
				mensagem_pergunta_dias_agendamento_superior:'',
				mensagem_pergunta_dias_agendamento_inferior:'',
				mensagem_pergunta_dias_agendamento_opcao_incorreta_superior:'',
				mensagem_pergunta_dias_agendamento_opcao_incorreta_inferior:'',
                }
			},
			setInciaisUsuer() {
				if(this.$store.getters.user){
					var name = this.$store.getters.user.nome
					var initials = name.match(/\b\w/g) || []
					this.user_iniciais = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
					this.usuario_id = this.$store.getters.user.usuario_id
				}
			},
		},
		watch: {
			tab_agendamento: function (new_, old_) {
				this.dados.tipo_agendamento = new_ == 'mensagens_agendamento' ? old_ : new_
				
			}
		},
		// funções que rodam quando o componente é montado
		mounted(){
			// função de início do componente
			this.init()
			this.socket.on("NOVO-CALENDAR", (data) => {
				if(data.calendario_id == this.calendar_retorno_id){
					this.dados.calendar = data.calendario_id;
					this.getCalendars()
					this.loading = false;
				}
			});


			this.socket.on("READY-WHATS", () => {
				this.socket.emit("REGISTRAR-USUARIO", {
					usuario_id: this.usuario_id,
				});
			});
		},
	}
</script>

<style lang="scss" scoped>
	#menus{
		display: flex;
		max-height: 100%;
		padding: 24px;
		flex-wrap: wrap;
		.tabela-menus-telefone{
			background-color: #fff;
			border-radius: 10px;
			.novo-menu{
				display: flex;
				justify-content: flex-end;
				padding-bottom: 0;
				button{
					padding: 0 5px !important;
					i, svg{
						margin-left: 10px;
					}
					text-transform: inherit;
				}
			}
		}
		.v-dialog{
			.v-card{
				background-color: #f9f9f9;
			}
			.headline{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				font-weight: 600;
				color: #11263C;
			}
		}
	}
</style>